exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-connect-js": () => import("./../../../src/pages/connect.js" /* webpackChunkName: "component---src-pages-connect-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-work-index-js": () => import("./../../../src/pages/work/index.js" /* webpackChunkName: "component---src-pages-work-index-js" */),
  "component---src-pages-work-mdx-frontmatter-name-js-content-file-path-content-fossil-finds-index-mdx": () => import("./../../../src/pages/work/{mdx.frontmatter__name}.js?__contentFilePath=/opt/build/repo/content/fossil-finds/index.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-name-js-content-file-path-content-fossil-finds-index-mdx" */),
  "component---src-pages-work-mdx-frontmatter-name-js-content-file-path-content-my-coding-journey-in-2023-index-mdx": () => import("./../../../src/pages/work/{mdx.frontmatter__name}.js?__contentFilePath=/opt/build/repo/content/my-coding-journey-in-2023/index.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-name-js-content-file-path-content-my-coding-journey-in-2023-index-mdx" */),
  "component---src-pages-work-mdx-frontmatter-name-js-content-file-path-content-oxfordshire-all-in-index-mdx": () => import("./../../../src/pages/work/{mdx.frontmatter__name}.js?__contentFilePath=/opt/build/repo/content/oxfordshire-all-in/index.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-name-js-content-file-path-content-oxfordshire-all-in-index-mdx" */),
  "component---src-pages-work-mdx-frontmatter-name-js-content-file-path-content-scavengar-app-index-mdx": () => import("./../../../src/pages/work/{mdx.frontmatter__name}.js?__contentFilePath=/opt/build/repo/content/scavengar-app/index.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-name-js-content-file-path-content-scavengar-app-index-mdx" */),
  "component---src-pages-work-mdx-frontmatter-name-js-content-file-path-content-share-one-world-index-mdx": () => import("./../../../src/pages/work/{mdx.frontmatter__name}.js?__contentFilePath=/opt/build/repo/content/share-one-world/index.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-name-js-content-file-path-content-share-one-world-index-mdx" */),
  "component---src-pages-work-mdx-frontmatter-name-js-content-file-path-content-tech-for-non-profits-index-mdx": () => import("./../../../src/pages/work/{mdx.frontmatter__name}.js?__contentFilePath=/opt/build/repo/content/tech-for-non-profits/index.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-name-js-content-file-path-content-tech-for-non-profits-index-mdx" */),
  "component---src-pages-work-mdx-frontmatter-name-js-content-file-path-content-website-launched-index-mdx": () => import("./../../../src/pages/work/{mdx.frontmatter__name}.js?__contentFilePath=/opt/build/repo/content/website-launched/index.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-name-js-content-file-path-content-website-launched-index-mdx" */),
  "component---src-pages-work-mdx-frontmatter-name-js-content-file-path-content-were-playing-basketball-index-mdx": () => import("./../../../src/pages/work/{mdx.frontmatter__name}.js?__contentFilePath=/opt/build/repo/content/were-playing-basketball/index.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-name-js-content-file-path-content-were-playing-basketball-index-mdx" */),
  "component---src-pages-work-mdx-frontmatter-name-js-content-file-path-content-what-my-emba-taught-me-index-mdx": () => import("./../../../src/pages/work/{mdx.frontmatter__name}.js?__contentFilePath=/opt/build/repo/content/what-my-emba-taught-me/index.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-name-js-content-file-path-content-what-my-emba-taught-me-index-mdx" */)
}

